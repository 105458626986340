.slider {
  width: 24px;
  height: 186px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 50%;
  bottom: 0;
  right: 40px;
  transform: translateY(-50%);
}

.dot {
  width: 12px;
  height: 12px;
  background: #ffffff;
  border-radius: 24px;
  box-sizing: content-box;
  border: 6px solid #1e1f25;
  transition: all 0.2s ease-in;
}

.dot_active {
  border: 6px solid rgba(76, 76, 80);
  border-radius: 24px;
}
