.bg {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  overflow: hidden;
  pointer-events: none;
}

.bg_line {
  margin: 0 60px;
  width: 1px;
  height: 100%;
  background-color: rgba(47, 50, 65, 1);
  opacity: 0.1;
  animation: line alternate infinite 1s ease-in;
}

@keyframes line {
  0% {
    opacity: 0.05;
  }
  100% {
    opacity: 1;
  }
}
