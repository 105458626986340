.header {
  z-index: 6;
  position: fixed;
  width: 100%;
}

.header_wrapper {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.header_content__right {
  display: flex;
  overflow-y: hidden;
}

.menu__items {
  display: flex;
  gap: 20px;
  margin-right: 40px;
  overflow: hidden;
}

.menu__items li {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
}

.menu__items a {
  color: white;
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  padding-top: 15px;
  padding-bottom: 15px;
}

.menu__items a:hover {
  opacity: 0.8;
}

.logo_title {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  display: flex;
  gap: 2px;
}
