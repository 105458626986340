* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gilroy', sans-serif;
}
html,
body {
  height: 100vh;
  overflow: hidden;
  background-color: #1e1f25;
}

.i {
  height: 100vh;
  position: relative;
}
