.container {
  width: 100%;
  padding: 0 20px;
  max-width: 1440px;
  margin: 0 auto;
  z-index: 10;
}

.content {
  height: 100vh;
  padding: 160px 0 80px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  font-weight: 500;
  font-size: 60px;
  line-height: 130%;
  letter-spacing: -0.019em;
  color: #ffffff;
  text-align: start;
}

.subtitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #dadada;
  text-align: start;
  margin-top: 12px;
}

.textWrapper {
  width: 100%;
  max-width: 700px;
}

.image_content {
  display: block;
  position: relative;
  flex: 1;
}

.image {
  position: absolute;
  z-index: 10;
}

.image1 {
  width: 240px;
  right: 300px;
  top: 100px;
}
.image2 {
  width: 280px;
  right: 100px;
}

.store {
  height: 50px;
}

.block1__svg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  z-index: 0;
}

@media (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 20px;
    max-width: 1440px;
    margin: 0 auto;
    z-index: 10;
  }

  .content {
    height: 100vh;
    padding: 90px 0 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  .title {
    font-size: 24px;
  }

  .subtitle {
    font-size: 16px;
  }

  .textWrapper {
    width: 100%;
  }

  .image_content {
    display: block;
    position: relative;
    flex: 1;
    width: 100%;
  }

  .image {
    position: absolute;
    z-index: 10;
  }

  .image1 {
    width: 80px;
    right: 200px;
    bottom: 40px;
  }
  .image2 {
    width: 110px;
    right: 100px;
    bottom: 80px;
  }

  .store {
    height: 40px;
  }

  .block1__svg {
    display: none;
  }
}
